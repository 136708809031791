import SvgModule from "./svgs";
import dateFormat from "utils/dateFormat";
import { matchData } from "data/matchData";
import { useState } from "react";
function Floating() {
  const [bannerClose, setBannerClose] = useState<boolean>(false);

  const floatingData = matchData
    .filter((match) => {
      const today = new Date();
      const isStarted = dateFormat(match.startDate, today).isTodayOrBefore;
      const isFinished = dateFormat(match.finishDate, today).isTodayOrLater;

      console.log(match.title, "isStarted" + isStarted, "isFinished" + isFinished);
      return isStarted && isFinished;
    })
    .reverse();

  return floatingData.length > 0 ? (
    <>
      <div className={`floating ${bannerClose ? "hidden" : ""}`}>
        <div className="floating-header">
          <p className="floating-livescore">
            <SvgModule type="shuttle" className="floating-livescore-icon" />
            <SvgModule type="liveScore" className="floating-livescore-title" />
          </p>

          <button className="floating-close" onClick={() => setBannerClose(true)}>
            <SvgModule type="close" className="floating-close-icon" />
          </button>
        </div>

        {floatingData.map((data, dataIndex) => (
          <div className="floating-container" key={dataIndex}>
            <p className="floating-title">{data.title}</p>
            <div className="floating-information">
              <p className="floating-information-date">
                {dateFormat(data.startDate).formattedDate} 〜 {dateFormat(data.finishDate).formattedDate}
              </p>
              <p className="floating-information-place">{data.place}</p>
            </div>
            <ul className="floating-list">
              {data.links.map((link, linkIndex) => (
                <li className={`floating-list-item ${data.links.length === 1 ? "full" : ""}`} key={linkIndex}>
                  <a href={link[1]} className="floating-list-item-link" target="_top">
                    <span className="floating-list-item-title">{link[0]}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {!bannerClose && <div className="floating-wrapper" onClick={() => setBannerClose(true)}></div>}

      <div className="floating-button" onClick={() => setBannerClose(false)}>
        <SvgModule type="shuttle" className="floating-button-icon" />
        <p className="floating-button-title">
          LIVE
          <br />
          SCORE
        </p>
      </div>
    </>
  ) : null;
}

export default Floating;
