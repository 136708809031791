import { format, isAfter, isBefore, isSameDay } from 'date-fns';

function dateFormat(date?: string | Date, compareDate?: string | Date) {
  const dateSet = date ? new Date(date) : new Date();
  const compareDateSet = compareDate ? new Date(compareDate) : new Date();

  const formattedDate = format(dateSet, 'yyyy年MM月dd日');
  const isTodayOrLater = isSameDay(dateSet, compareDateSet) || isAfter(dateSet, compareDateSet);
  const isTodayOrBefore = isSameDay(dateSet, compareDateSet) || isBefore(dateSet, compareDateSet);
  const isBeforeDate = !isSameDay(dateSet, compareDateSet) && isBefore(dateSet, compareDateSet);

  return {
    formattedDate,
    isAfter: isAfter(dateSet, compareDateSet),
    isBefore: isBefore(dateSet, compareDateSet),
    isTodayOrLater,
    isTodayOrBefore,
    isBeforeDate,
  };
}

export default dateFormat;
