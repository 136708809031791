import { compareDesc } from 'date-fns';

const rawMatchData = [
  {
    title: 'SAGA2024 国スポ バドミントン競技',
    startDate: '2024-10-05',
    finishDate: '2024-10-08',
    place: '唐津市文化体育館',
    links: [
      ['大会WEBサイト', 'https://www.birdscore.live/web/saga2024-badminton/'],
    ],
  },
  {
    title: '第75回 全国高等学校バドミントン選手権大会',
    startDate: '2024-07-31',
    finishDate: '2024-08-01',
    place: 'SAGAアリーナ、SAGAプラザ、\n諸富文化体育館、吉野ケ里文化体育館',
    links: [
      ['男子学校対抗', 'https://www.birdscore.live/web/interhigh-2024-saga-bt/'],
      ['女子学校対抗', 'https://www.birdscore.live/web/interhigh-2024-saga-gt/'],
      ['男子個人対抗', 'https://www.birdscore.live/web/interhigh-2024-saga-bds/'],
      ['女子個人対抗', 'https://www.birdscore.live/web/interhigh-2024-saga-gds/'],
    ],
  },
  {
    title: '第52回 全国高等学校選抜バドミントン大会',
    startDate: '2024-3-27',
    finishDate: '2024-3-28',
    place: '滋賀ダイハツアリーナ、\nYMITアリーナ（くさつシティアリーナ）',
    links: [
      ['男女個人戦', 'https://www.birdscore.live/web/senbatsu-2023-shiga-bds/'],
      ['男子団体戦', 'https://www.birdscore.live/web/senbatsu-2023-shiga-bt/'],
      ['女子団体戦', 'https://www.birdscore.live/web/senbatsu-2023-shiga-gt/'],
    ],
  },
  {
    title: 'BADMINTON\nS/J LEAGUE 2023',
    startDate: '2023-11-4',
    finishDate: '2024-2-24',
    place: '東京体育館他 各地会場',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/sj-league2023/']],
  },
  {
    title: '第77回 全日本総合バドミントン選手権大会',
    startDate: '2023-12-25',
    finishDate: '2023-12-30',
    place: '武蔵野の森総合スポーツプラザ',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/77alljapan/']],
  },
  {
    title: 'BADMINTON\nS/J LEAGUEⅡ 2023',
    startDate: '2023-11-16',
    finishDate: '2023-11-19',
    place: '唐津市文化体育館',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/sj-league2-2023/']],
  },
  {
    title: '燃ゆる感動かごしま国体 バドミントン競技',
    startDate: '2023-10-13',
    finishDate: '2023-10-16',
    place: '指宿総合体育館',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/kagoshima2023-badminton/']],
  },
  {
    title: '第51回 全国高等学校選抜バドミントン大会',
    startDate: '2023-3-25',
    finishDate: '2023-3-28',
    place: '花巻市総合体育館',
    links: [
      ['男子団体戦', 'https://www.birdscore.live/web/senbatsu-2022-iwate-bt/'],
      ['女子団体戦', 'https://www.birdscore.live/web/senbatsu-2022-iwate-gt/'],
      ['男子個人戦', 'https://www.birdscore.live/web/senbatsu-2022-iwate-bds/'],
      ['女子個人戦', 'https://www.birdscore.live/web/senbatsu-2022-iwate-gds/'],
    ],
  },
  {
    title: '第76回 全日本総合バドミントン選手権大会',
    startDate: '2022-12-24',
    finishDate: '2022-12-30',
    place: '武蔵野の森総合スポーツプラザ',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/76alljapan/']],
  },
  {
    title: 'いちご一会とちぎ国体 バドミントン競技会',
    startDate: '2022-10-7',
    finishDate: '2022-10-10',
    place: '栃木県立県北体育館',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/tochigikokutai2022-badminton/']],
  },
  {
    title: '第73回 全国高等学校バドミントン選手権大会',
    startDate: '2022-7-24',
    finishDate: '2022-7-28',
    place: '日本フネン市民プラザ、他',
    links: [
      ['男子学校対抗', 'https://www.birdscore.live/web/interhigh-2022-tokushima-bt/'],
      ['女子学校対抗', 'https://www.birdscore.live/web/interhigh-2022-tokushima-gt/'],
      ['男子個人対抗', 'https://www.birdscore.live/web/interhigh-2022-tokushima-bds/'],
      ['女子個人対抗', 'https://www.birdscore.live/web/interhigh-2022-tokushima-gds/'],
    ],
  },
  {
    title: '第72回 全日本実業団バドミントン選手権大会',
    startDate: '2022-6-15',
    finishDate: '2022-6-19',
    place: '丸善インテックアリーナ大阪\n（大阪市中央体育館）',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/72jitsugyodan-osaka/']],
  },
  {
    title: '第50回 記念全国高等学校選抜バドミントン大会',
    startDate: '2022-3-25 ',
    finishDate: ' 2022-3-26',
    place: '栃木県宇都宮市・会場：日環アリーナ栃木',
    links: [
      ['男子団体戦', 'https://www.birdscore.live/web/senbatsu-2021-tochigi-bt/'],
      ['女子団体戦', 'https://www.birdscore.live/web/senbatsu-2021-tochigi-gt/'],
      ['男子個人戦', 'https://www.birdscore.live/web/senbatsu-2021-tochigi-bds/'],
      ['女子個人戦', 'https://www.birdscore.live/web/senbatsu-2021-tochigi-gds/'],
    ],
  },
  {
    title: '第75回 全日本総合バドミントン選手権大会',
    startDate: '2021-12-25',
    finishDate: '2021-12-30',
    place: '武蔵野の森総合スポーツプラザ',
    links: [['大会WEBサイト', 'https://www.birdscore.live/web/75alljapan/']],
  },
  {
    title: '令和3年度 全国高等学校総合体育大会バドミントン競技大会',
    startDate: '2021-8-9',
    finishDate: '2021-8-10',
    place:
      '高岡市竹平記念体育館・高岡市ふくおか総合文化センター（Uホール）、アルビス小杉総合体育センター・新湊アイシン軽金属スポーツセンター',
    links: [
      ['男子団体戦', 'https://www.birdscore.live/web/interhigh-2021-toyama-bt/'],
      ['女子団体戦', 'https://www.birdscore.live/web/interhigh-2021-toyama-gt/'],
      ['男子個人戦', 'https://www.birdscore.live/web/interhigh-2021-toyama-bds/'],
      ['女子個人戦', 'https://www.birdscore.live/web/interhigh-2021-toyama-gds/'],
    ],
  },
];

export const matchData = rawMatchData.sort((a, b) => {
  const startDateComparison = compareDesc(new Date(a.startDate), new Date(b.startDate));
  if (startDateComparison !== 0) return startDateComparison;
  return compareDesc(new Date(a.finishDate), new Date(b.finishDate));
});